import { destroy, get, post, put } from '@/application/api/implementations/app';

export const index = () => {
  return get('setting/candidate/document-category');
};

export const tree = () => {
  return get('candidate/document-category/tree');
};

export const create = (documentCategory) => {
  return post('setting/candidate/document-category', documentCategory);
};

export const update = (documentCategory) => {
  return put(`setting/candidate/document-category/${documentCategory.id}`, documentCategory);
};

export const show = (documentCategoryId) => {
  return get(`setting/candidate/document-category/${documentCategoryId}`);
};

export const remove = (documentCategoryId) => {
  return destroy(`setting/candidate/document-category/${documentCategoryId}`);
};
