<template>
  <PageLayout>
    <template #header>
      <k-title>{{ candidate.name }}</k-title>
    </template>
    <template #flow-actions>
      <v-btn
        color="primary"
        depressed
        tile
        @click="$refs.file.click()"
      >
        {{ $t('actions.createResource', { resource: $tc('document.title', 1) }) }}
      </v-btn>
      <input
        ref="file"
        class="d-none"
        type="file"
        @change="fileSelected"
      >
      <DocumentForm
        v-model="createDialog"
        :folders="folders"
        :request="createRequest"
        :title="$tc('document.title',1)"
        :values="createFormValues"
        @change="$refs.fileManager.reload()"
      />
      <DocumentForm
        v-model="updateDialog"
        :folders="folders"
        :request="updateRequest"
        :title="$tc('document.title',1)"
        :values="updateFormValues"
        is-update-form
        @change="$refs.fileManager.reload()"
      />
    </template>
    <template
      v-if="candidate"
      #default
    >
      <KFileManager
        ref="fileManager"
        :file-fetcher="fetchFiles"
        :folders="mappedFolders"
        class="mt-3"
        @documentDropped="documentDropped"
        @click:edit="openUpdate"
      />
    </template>
  </PageLayout>
</template>

<script>
import Document from '@/application/models/Document.js';
import PageLayout from '@/components/layout/PageLayout.vue';
import { show, update } from '@/modules/document/api/document.js';
import DocumentForm from '@/modules/document/components/DocumentForm.vue';
import KFileManager from '@/modules/document/components/fileManager/KFileManager.vue';
import { create, index } from '@/modules/candidate/api/candidateDocument.js';
import { tree } from '@/modules/candidate/api/candidateDocumentCategory.js';
import KTitle from '@/components/layout/KTitle.vue';
import eventBus from '@/application/eventBus.ts';

export default {
  name: 'CandidateFileManager',
  components: {
    KTitle,
    DocumentForm,
    PageLayout,
    KFileManager,
  },
  props: {
    candidate: {
      type: Object,
    },
  },
  data: () => ({
    createDialog: false,
    createFormValues: new Document(),
    updateDialog: false,
    updateFormValues: new Document(),
    folders: [],
  }),
  computed: {
    mappedFolders() {
      return this.folders.map(category => {
        return {
          id: category.id,
          name: category.name,
          children: category.documentTypes.map(type => {
            return {
              id: `${category.id}.${type.id}`,
              typeId: type.id,
              categoryId: category.id,
              name: type.name,
              canRead: type.canRead,
              canCreate: type.canCreate,
            };
          }),
        };
      });
    },
  },
  watch: {
    createDialog() {
      this.$refs.file.value = '';
    },
  },
  created() {
    this.fetchFolders();

    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'candidate.index' },
        text: this.$tc('employer.title', 2),
      },
      {
        exact: true,
        to: { name: 'candidate.show' },
        text: this.candidate.name,
      },
      {
        exact: true,
        to: { name: 'candidate.documents' },
        text: this.$tc('document.title', 2),
      }],
    );
  },
  methods: {
    async fetchFolders() {
      const response = await tree();
      this.folders = response.data.data;
    },
    fetchFiles(page, perPage, search, sortBy, descending, documentCategories = [], documentTypes = []) {
      return index(this.candidate.id, page, perPage, search, sortBy, descending, documentCategories, documentTypes);
    },
    createRequest() {
      return create(this.candidate.id, this.createFormValues);
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    documentDropped(event) {
      const {
        files,
        categoryId,
        typeId,
      } = event;
      const file = files[0];
      const category = this.folders.find(folder => folder.id === categoryId);

      this.createFormValues.mapResponse({
        file: file,
        name: file.name,
        documentCategory: category,
        documentType: category.documentTypes.find(folder => folder.id === typeId),
      });

      this.createDialog = true;
    },
    fileSelected(event) {
      this.createFormValues = new Document();
      const file = event.target.files[0];
      this.createFormValues.file = file;
      this.createFormValues.name = file.name;
      this.createDialog = true;
    },
    async openUpdate(id) {
      this.updateFormValues = new Document();
      const request = await show(id);
      this.updateFormValues.mapResponse(request.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
