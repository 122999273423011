import { post } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (candidateId, page, perPage, search, sortBy, descending, documentCategories = [], documentTypes = [], params) => {
  return getPaginated(`candidate/${candidateId}/document`, page, perPage, search, sortBy, descending,
    {
      ...params,
      documentCategories,
      documentTypes,
    });
};

export const create = (candidateId, document) => {
  return post(`candidate/${candidateId}/document`, document);
};
